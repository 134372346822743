import { action, computed, observable } from 'mobx';
import validate from 'mobx-form-validation';
import InvoiceBasicForm from '@/store/invoice/invoiceBasicForm';
import InvoiceProducts from '@/store/invoice/invoiceProducts';
import StoreConstructor from '@/store/core/StoreConstructor';
import services from '@/api/services';
import moment from 'moment';
import { formatValue } from '@/utils';
import { Fee } from '@/store/types';

export default class CreateBasicInvoice extends StoreConstructor {
  @observable loading = false;
  @observable id = 1;

  @observable
  @validate((value: any) => value.validateError)
  invoiceForm = {} as InvoiceBasicForm;

  @observable
  @validate((value: any) =>
    value.map((v: any) => v.validateError).find((v: any) => v),
  )
  feeList: InvoiceProducts[] = [];

  @computed get amountPriceSum() {
    return this.feeList
      .reduce((sum, product) => sum + Number(product.amountPrice), 0)
      .toFixed(2);
  }

  @computed get taxSum() {
    return this.feeList
      .reduce((sum, product) => sum + Number(product.taxAmount), 0)
      .toFixed(2);
  }

  @action
  addFee(initialProduct?: Fee) {
    this.feeList.push(new InvoiceProducts(this.id, initialProduct));
    this.id += 1;
  }

  @action
  clearFee(id: number | null) {
    if (!id) return;
    const index = this.feeList.findIndex(
      (fee: InvoiceProducts) => fee.id === id,
    );
    if (index >= 0) this.feeList.splice(index, 1);
  }

  @action createInvoice() {
    this.invoiceForm = new InvoiceBasicForm();
    this.addFee();
  }

  @action async sendInvoice() {
    const payload = {
      ...this.invoiceForm,
      legalPersonId: this.stores.user.legalPersonId,
      currency: this.invoiceForm.currency.value,
      invoiceDate: moment(this.invoiceForm.invoiceDate).format('YYYY-MM-DD'),
      amountPriceSum: formatValue(this.amountPriceSum),
      taxSum: formatValue(this.taxSum),
      fees: this.feeList.map((fee) => {
        return {
          ...fee,
          amountPrice: formatValue(fee.amountPrice),
          priceItems: formatValue(fee.priceItems),
          taxAmount: formatValue(fee.taxAmount),
          taxRate: fee.taxRate.value,
        };
      }),
    };

    this.loading = true;

    await services.invoice.sendInvoice(payload);
    setTimeout(() => this.stores.invoiceList.fetch(), 1000);

    this.loading = false;
  }

  @action.bound clearInvoice() {
    this.id = 1;
    this.invoiceForm = new InvoiceBasicForm();
    this.feeList = [];
  }
}
