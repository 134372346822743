import { action, computed, observable } from 'mobx';
import { elasticApi } from '@/api';
import ListStore from '@/store/core/ListStore';
import { computedTinLabel, downloadXlsxFromBuffer, numGroup } from '@/utils';
import axios from 'axios';
import Excel from 'exceljs';
import { i18n } from '@/i18n';

export default class PartnerList extends ListStore<any> {
  aggsFields = ['sum', 'overallTaxSum', 'overallPriceItems'];
  @observable partnersType: 'buyer' | 'seller' | '' = '';
  @observable count = {
    buyer: 0,
    seller: 0,
  };

  @action.bound async generateUpload() {
    if (this.loading) return;
    this.loading = true;
    try {
      const { items: buyerItems } = await this.fetchData(true, 'buyer');
      const buyerMappedItems = buyerItems.map(
        this.itemsMapFunction.bind(this),
      ) as typeof this.itemsView;

      const { items: sellerItems } = await this.fetchData(true, 'seller');
      const sellerMappedItems = sellerItems.map(
        this.itemsMapFunction.bind(this),
      ) as typeof this.itemsView;

      const preparedBuyerItems = [
        ...buyerMappedItems.map((item) => [
          item.taxNumber,
          item.invoiceCount,
          item.overallPriceItems,
          item.overallTaxSum,
          item.sum,
        ]),
      ];

      const preparedSellerItems = [
        ...sellerMappedItems.map((item) => [
          item.taxNumber,
          item.invoiceCount,
          item.overallPriceItems,
          item.overallTaxSum,
          item.sum,
        ]),
      ];

      const { data: file } = await axios.get(
        `/template/${i18n.locale}/ЛК НП. Реестр контрагентов.xlsx`,
        {
          responseType: 'arraybuffer',
        },
      );
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(file);
      const buyersWorksheet = workbook.getWorksheet(
        i18n.t('downloads.buyers') as string,
      );

      for (let i = 0; i < preparedBuyerItems.length; i++) {
        buyersWorksheet.getRow(i + 2).values = preparedBuyerItems[i];
      }

      const sellersWorksheet = workbook.getWorksheet(
        i18n.t('downloads.sellers') as string,
      );

      for (let i = 0; i < preparedSellerItems.length; i++) {
        sellersWorksheet.getRow(i + 2).values = preparedSellerItems[i];
      }

      const buffer = await workbook.xlsx.writeBuffer();
      downloadXlsxFromBuffer(i18n.t('downloads.contractors') as string, buffer);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async setType(type: typeof this.partnersType) {
    await this.setPage(0);
    this.partnersType = type;
  }

  @action.bound async fetchData(
    ignorePages = false,
    partnersType: string | null = null,
  ) {
    const { start: gte, end: lte } = this.stores.period.range;

    const queryRange = {
      range: {
        invoiceDate: { gte, lte },
      },
    };

    const queryPartnersType = partnersType || this.partnersType;

    const querySearch = {
      wildcard: {
        [queryPartnersType + 'TaxNumber']: `${this.search}*`,
      },
    };

    const countQuerySearch = [
      {
        wildcard: {
          buyerTaxNumber: `${this.search}*`,
        },
      },
      {
        wildcard: {
          sellerTaxNumber: `${this.search}*`,
        },
      },
    ];

    const queryTaxNumber = {
      term: {
        [queryPartnersType === 'buyer'
          ? 'sellerTaxNumber.keyword'
          : 'buyerTaxNumber.keyword']: this.stores.user.taxNumber,
      },
    };

    const params: any[] = [];

    params.push(queryRange, queryTaxNumber);

    if (this.search) params.push(querySearch);

    const counts = await elasticApi.post('invoice_index/_search', {
      track_total_hits: true,
      size: 0,
      query: {
        bool: {
          must: [
            queryRange,
            ...(this.search && [
              {
                bool: {
                  should: countQuerySearch,
                  minimum_should_match: 1,
                },
              },
            ]),
          ],
          should: [
            { match: { sellerTaxNumber: this.stores.user.taxNumber } },
            { match: { buyerTaxNumber: this.stores.user.taxNumber } },
          ],
          minimum_should_match: 1,
        },
      },
      aggs: {
        seller_group: {
          filter: {
            bool: {
              must: [
                { term: { buyerTaxNumber: this.stores.user.taxNumber } },
                {
                  bool: {
                    must_not: [
                      { term: { 'status.keyword': 'DECLINED' } },
                      { term: { 'docType.keyword': 'ANNULLING' } },
                    ],
                  },
                },
              ],
            },
          },
          aggs: {
            unique_sellers: {
              cardinality: { field: 'sellerTaxNumber.keyword', missing: '' },
            },
          },
        },
        buyer_group: {
          filter: { term: { sellerTaxNumber: this.stores.user.taxNumber } },
          aggs: {
            unique_buyers: {
              cardinality: { field: 'buyerTaxNumber.keyword', missing: '' },
            },
          },
        },
      },
    });

    if (!ignorePages) {
      this.count = {
        buyer: counts.data?.aggregations?.buyer_group?.unique_buyers?.value,
        seller: counts.data?.aggregations?.seller_group?.unique_sellers?.value,
      };
    }

    const response = await elasticApi.post('invoice_index/_search', {
      from: !ignorePages ? this.page * this.pageSize : 0,
      size: !ignorePages ? this.pageSize : 10_000,
      track_total_hits: true,
      ...(![...this.aggsFields, 'invoiceCount'].includes(this.sort.prop) &&
        this.sort.prop && {
          sort: [{ [this.sort.prop]: this.sort.direction }],
        }),
      query: {
        bool: {
          must: params,
          ...(queryPartnersType === 'seller' && {
            must_not: [
              { term: { 'status.keyword': 'DECLINED' } },
              { term: { 'docType.keyword': 'ANNULLING' } },
            ],
          }),
        },
      },
      aggs: {
        by_partner: {
          terms: {
            field: queryPartnersType + 'TaxNumber.keyword',
            ...(this.sort.prop === 'invoiceCount' && {
              order: {
                _count: this.sort.direction,
              },
            }),
            size: 10_000,
          },
          aggs: {
            overallTaxSum: {
              sum: {
                script:
                  'if(doc["docType.keyword"].value != "ANNULLING" && doc["status.keyword"].value == "APPROVED" && (doc["acceptStatus.keyword"].value == "ACCEPTED" || doc["acceptStatus.keyword"].value == "AUTO_ACCEPTED")) doc["taxSum"].value',
              },
            },
            sum: {
              sum: {
                script:
                  'if(doc["docType.keyword"].value != "ANNULLING" && doc["status.keyword"].value == "APPROVED" && (doc["acceptStatus.keyword"].value == "ACCEPTED" || doc["acceptStatus.keyword"].value == "AUTO_ACCEPTED")) doc["amountPriceSum"].value',
              },
            },
            overallPriceItems: {
              sum: {
                script:
                  'if(doc["docType.keyword"].value != "ANNULLING" && doc["status.keyword"].value == "APPROVED" && (doc["acceptStatus.keyword"].value == "ACCEPTED" || doc["acceptStatus.keyword"].value == "AUTO_ACCEPTED")) doc["amountPriceSum"].value - doc["taxSum"].value',
              },
            },
            ...(this.aggsFields.includes(this.sort.prop) && {
              sum_sort: {
                bucket_sort: {
                  sort: [{ [this.sort.prop]: this.sort.direction }],
                },
              },
            }),
          },
        },
      },
    });

    return {
      items: response.data?.aggregations.by_partner.buckets || [],
      totalItems: response.data?.aggregations.by_partner.buckets.length || 0,
    };
  }

  @action.bound itemsMapFunction(item: any) {
    return {
      ...item,
      taxNumber: computedTinLabel(item.key),
      invoiceCount: numGroup(item.doc_count, { fractionDigits: 0 }),
      sum: numGroup(item.sum.value),
      overallTaxSum: numGroup(item.overallTaxSum.value),
      overallPriceItems: numGroup(item.overallPriceItems.value),
    };
  }

  @computed get itemsView() {
    return this.items.map(this.itemsMapFunction.bind(this));
  }

  @computed get pageableItemsView() {
    return this.itemsView.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize,
    );
  }
}
