import { computed, observable } from 'mobx';
import validate from 'mobx-form-validation';
import { Fee } from '@/store/types';
import { TaxRatesOptions } from '@/consts';

export default class InvoiceProducts {
  constructor(id: number, initialProduct?: Fee) {
    this.id = id;

    if (initialProduct) {
      this.itemName = initialProduct.itemName;
      this.itemCode = initialProduct.itemCode;
      this.quantity = initialProduct.quantity;
      this.price = initialProduct.price;
      this.taxRate =
        TaxRatesOptions.find(
          (option) => option.value === Number(initialProduct.taxRate),
        ) || TaxRatesOptions[0];
    }
  }

  @observable id: null | number = null;

  @observable
  @validate(/.*\S.*/, 'page_create_invoice.validation.name')
  itemName: Fee['itemName'] = '';

  @observable
  @validate(/^[0-9]+$/, 'page_create_invoice.validation.code')
  itemCode: Fee['itemCode'] = '';

  @observable
  @validate(/^[0-9]+$/, 'page_create_invoice.validation.qnt')
  quantity: Fee['quantity'] = 1;

  @observable
  @validate(/^\d{1,8}(\.\d{1,2})?$/, 'page_create_invoice.validation.price')
  price: Fee['price'] | '' = '';

  @computed
  @validate(/^[.0-9]+$/, 'page_create_invoice.validation.price_items')
  get priceItems(): Fee['priceItems'] | string {
    return (Number(this.price) * Number(this.quantity)).toFixed(2);
  }

  @observable
  taxRate: Fee['taxRate'] = { value: 20, label: 20 };

  @computed
  @validate(/^[.0-9]+$/, 'page_create_invoice.validation.amount_price')
  get amountPrice(): Fee['amountPrice'] | string {
    return (
      Number(this.priceItems) *
      ((100 + Number(this.taxRate.value)) / 100)
    ).toFixed(2);
  }

  @computed
  @validate(/^[.0-9]+$/, 'page_create_invoice.validation.tax_amount')
  get taxAmount(): Fee['taxAmount'] | string {
    return (
      Number(this.priceItems) *
      (Number(this.taxRate.value) / 100)
    ).toFixed(2);
  }
}
