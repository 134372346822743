import { ApiService } from '@/api/services/core';
import { invoiceApi, stoTaxPrinterProxyApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';

export class InvoiceService extends ApiService {
  sendInvoice(props: any) {
    return invoiceApi.post(ENDPOINTS.invoice.sendInvoice, props);
  }

  nullifyingInvoice(params: Record<string, any>) {
    return invoiceApi.post(ENDPOINTS.invoice.nullifying, params);
  }

  rejectInvoice(uuid: string) {
    return invoiceApi.post(ENDPOINTS.invoice.reject(uuid));
  }

  acceptInvoice(uuid: string) {
    return invoiceApi.post(ENDPOINTS.invoice.accept(uuid));
  }

  getInvoiceDocument(params: {
    uuid: string;
    lang: string;
    fileType?: 'PDF' | 'PNG';
  }) {
    if (!params?.fileType) params.fileType = 'PDF';
    return stoTaxPrinterProxyApi.get(ENDPOINTS.invoice.getLink, {
      responseType: 'blob',
      params,
    });
  }

  getInvoiceLink(uuid: string, fileType: 'PDF' | 'PNG' = 'PDF', lang: string) {
    return (
      stoTaxPrinterProxyApi.defaults.baseURL +
      ENDPOINTS.invoice.getLink +
      `?uuid=${uuid}&lang=${lang}&fileType=${fileType}`
    );
  }

  sendInvoiceByEmail(
    tin: string,
    subject: string,
    html: string,
    formData: FormData,
  ) {
    return stoTaxPrinterProxyApi.post(
      ENDPOINTS.invoice.sendEmail(tin, subject, html),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }
}
