import { createUrl } from '@/utils';

const auth = createUrl('auth');
const info = createUrl('info');

const login = auth('login');
const refresh = auth('refresh');

const taxpayerInfo = (tin: string) => info(`taxpayers/${tin}`);

export const authEndpoints = { login, refresh, taxpayerInfo };
