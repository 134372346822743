import { action, observable } from 'mobx';
import validate from 'mobx-form-validation';
import { Invoice } from '@/store/types';
import stores from '@/store/core/RootStore';
import services from '@/api/services';
import { i18n } from '@/i18n';
import { compareWithTin, tinPattern } from '@/utils/validate';

export default class InvoiceBasicForm {
  @observable
  docType: Invoice['docType'] = 'BASIC';

  @observable
  @validate(/^[0-9]+$/, 'page_create_invoice.validation.serial_number')
  serialNumber: Invoice['serialNumber'] = Date.now();

  @observable
  invoiceDate: Invoice['invoiceDate'] = new Date();

  @observable
  @validate(
    /^[0-9]{10}$|^[0-9]{14}$/,
    'page_create_invoice.validation.seller_tin',
  )
  sellerTaxNumber: Invoice['sellerTaxNumber'] = stores.user.taxNumber;

  @observable
  @validate((value: string | number) => {
    if (!tinPattern.test(String(value)))
      return 'page_create_invoice.validation.contractor_tin';
    if (compareWithTin(stores.user.taxNumber).test(String(value)))
      return 'page_create_invoice.validation.same_tin';
  })
  buyerTaxNumber: Invoice['buyerTaxNumber'] = '';

  @observable
  currency: Invoice['currency'] = { value: 'USD', label: 'USD' };

  @observable contractorName = '';

  @action.bound async getContractorName() {
    if (![10, 14].includes(this.buyerTaxNumber.length))
      return (this.contractorName = i18n.t('actions.undefined') as string);
    try {
      const contractorName = await services.taxpayer.getTaxpayerInfo(
        this.buyerTaxNumber,
      );
      this.contractorName = contractorName?.data?.name;
    } catch (e) {
      console.error(e);
      this.contractorName = i18n.t(
        'page_create_invoice.validation.not_found',
      ) as string;
    }
  }
}
