export const months = [
  { value: '01', label: 'months.january.full' },
  { value: '02', label: 'months.february.full' },
  { value: '03', label: 'months.march.full' },
  { value: '04', label: 'months.april.full' },
  { value: '05', label: 'months.may.full' },
  { value: '06', label: 'months.june.full' },
  { value: '07', label: 'months.july.full' },
  { value: '08', label: 'months.august.full' },
  { value: '09', label: 'months.september.full' },
  { value: '10', label: 'months.october.full' },
  { value: '11', label: 'months.november.full' },
  { value: '12', label: 'months.december.full' },
];
