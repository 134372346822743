import { action, computed, observable } from 'mobx';
import { elasticApi } from '@/api';
import ListStore from '@/store/core/ListStore';
import { InvoiceCardDocType } from '@/consts';
import { Fee, Invoice } from '@/store/types';
import moment from 'moment';
import services from '@/api/services';
import {
  computedStatusColor,
  computedStatusLabel,
  computedTinLabel,
  getUniqueValuesString,
  numGroup,
} from '@/utils';
import { i18n } from '@/i18n';

export default class partnerInvoiceCard extends ListStore<Invoice> {
  @observable uuid = '';
  @observable taxNumber = '';
  @observable legalName = '';
  @observable parent?: Invoice;

  @action.bound async fetchData() {
    const querySearch = [
      { match: { sellerTaxNumber: this.stores.user.taxNumber } },
      { match: { buyerTaxNumber: this.stores.user.taxNumber } },
    ];

    const serialNumberSearch = {
      term: {
        'uuid.keyword': this.uuid,
      },
    };

    const params: any[] = [];

    params.push(serialNumberSearch);

    const response = await elasticApi.post<{
      data: Invoice[];
      totalItems: number;
    }>('invoice_index/_search', {
      from: 0,
      size: 1,
      query: {
        bool: {
          must: params,
          should: querySearch,
        },
      },
    });

    const invoice = response.data?.data[0];

    if (invoice?.parentNumber) {
      const parentInvoice = await elasticApi.get<{ data: Invoice[] }>(
        `invoice_index/_doc/${invoice.parentNumber}`,
      );
      this.parent = parentInvoice.data.data[0];
    } else {
      this.parent = undefined;
    }

    return {
      items: response.data?.data || [],
      totalItems: response.data?.totalItems || 0,
    };
  }

  @action.bound getInvoiceDocument() {
    return services.invoice.getInvoiceDocument({
      uuid: this.items[0].uuid,
      lang: i18n.locale.toUpperCase(),
    });
  }

  @action.bound sendInvoiceLinkByEmail(
    tin: string,
    subject: string,
    html: string,
    formData: FormData,
  ) {
    return services.invoice.sendInvoiceByEmail(tin, subject, html, formData);
  }

  @action.bound getInvoiceLink(fileType: 'PDF' | 'PNG' = 'PDF') {
    return services.invoice.getInvoiceLink(
      this.items[0].uuid,
      fileType,
      i18n.locale.toUpperCase(),
    );
  }

  @action.bound async rejectInvoice() {
    await services.invoice.rejectInvoice(this.items[0].uuid);
  }

  @action.bound async acceptInvoice() {
    await services.invoice.acceptInvoice(this.items[0].uuid);
  }

  @action.bound async nullifyingInvoice() {
    const payload = {
      docType: 'ANNULLING',
      serialNumber: Date.now(),
      invoiceDate: moment(new Date()).format('YYYY-MM-DD'),
      parentNumber: this.items[0].uuid,
      sellerTaxNumber: this.items[0].sellerTaxNumber,
      buyerTaxNumber: this.items[0].buyerTaxNumber,
      legalPersonId: this.items[0].legalPersonId,
      taxSum: this.items[0].taxSum,
      amountPriceSum: this.items[0].amountPriceSum,
      fees: this.items[0].fees,
      currency: this.items[0].currency,
    };
    await services.invoice.nullifyingInvoice(payload);
  }

  @computed get invoiceType() {
    const legalPersonId = this.items[0]?.legalPersonId;
    return this.stores.user.legalPersonId === legalPersonId
      ? 'outcome'
      : 'income';
  }

  @computed get itemsView() {
    return {
      ...this.items[0],
      docType: i18n.t(InvoiceCardDocType[this.items[0]?.docType]),
      invoiceDate: moment(this.items[0]?.invoiceDate).format('DD.MM.YYYY'),
      acceptStatusLabel: computedStatusLabel(
        this.items[0]?.acceptStatus,
        this.items[0]?.status,
        this.items[0]?.docType,
        this.invoiceType,
      ),
      acceptStatusColor: computedStatusColor(
        this.items[0]?.acceptStatus,
        this.items[0]?.status,
      ),
      taxSum: numGroup(this.items[0]?.taxSum),
      amountPriceSum: numGroup(this.items[0]?.amountPriceSum),
      taxRates: getUniqueValuesString<Fee>(this.items[0]?.fees, 'taxRate'),
      overallPriceItems: numGroup(
        this.items[0]?.amountPriceSum - this.items[0]?.taxSum,
      ),
      buyerTaxNumber: computedTinLabel(this.items[0]?.buyerTaxNumber),
      sellerTaxNumber: computedTinLabel(this.items[0]?.sellerTaxNumber),
      fees: this.items[0]?.fees.map((fee) => ({
        ...fee,
        priceItems: numGroup(fee.priceItems),
        amountPrice: numGroup(fee.amountPrice),
        price: numGroup(fee.price),
        taxAmount: numGroup(fee.taxAmount),
      })),
    };
  }
}
