import { RouteConfig } from 'vue-router';

const AuthView = () => import('@/views/auth/auth.vue');
const AuthRegisterView = () => import('@/views/auth/register/register.vue');
const AuthRegisterIndex = () => import('@/views/auth/register/index.vue');

const AuthLoginView = () => import('@/views/auth/login/login.vue');
const AuthLoginIndex = () => import('@/views/auth/login/index.vue');

export default {
  path: '/auth',
  component: AuthView,
  children: [
    {
      path: 'register',
      component: AuthRegisterView,
      children: [
        {
          path: '',
          name: 'auth-register-index',
          meta: { layout: 'auth' },
          component: AuthRegisterIndex,
        },
      ],
    },
    {
      path: 'login',
      component: AuthLoginView,

      children: [
        {
          path: '',
          name: 'auth-login-index',
          meta: { layout: 'auth', abbreviationDisabled: true },
          component: AuthLoginIndex,
        },
      ],
    },
  ],
} as RouteConfig;
