import { action, computed, observable, override } from 'mobx';
import moment from 'moment';
import { elasticApi } from '@/api';
import { Fee, Invoice } from '@/store/types';
import ListStore, { Sort } from '@/store/core/ListStore';
import { InvoiceDocTypeColor, InvoiceDocTypeLabel } from '@/consts';
import { RootStores } from '@/store/core/RootStore';
import {
  computedStatusColor,
  computedStatusLabel,
  downloadXlsxFromBuffer,
  getUniqueValuesString,
  numGroup,
} from '@/utils';
import services from '@/api/services';
import axios from 'axios';
import Excel from 'exceljs';
import { i18n } from '@/i18n';

export default class InvoiceList extends ListStore<Invoice> {
  @observable invoiceType: 'income' | 'outcome' | '' = '';
  @observable taxNumber = '';
  @observable legalName = '';
  @observable count = {
    income: 0,
    outcome: 0,
  };
  @override sort: Sort = {
    prop: 'invoiceDateTime',
    direction: 'desc',
  };

  constructor(stores: RootStores) {
    super(stores);
  }

  @action.bound async generateUpload() {
    if (this.loading) return;
    this.loading = true;
    try {
      const { items: incomeItems } = await this.fetchData(true, 'income');
      const incomeMappedItems = incomeItems.map(
        this.itemsMapFunction.bind(this),
      ) as typeof this.itemsView;

      const { items: outcomeItems } = await this.fetchData(true, 'outcome');
      const outcomeMappedItems = outcomeItems.map(
        this.itemsMapFunction.bind(this),
      ) as typeof this.itemsView;

      const preparedIncomeItems = [
        ...incomeMappedItems.map((item) => [
          item.serialNumber,
          item.invoiceDate,
          item.uuid,
          item.parentNumber,
          item.sellerTaxNumber,
          item.overallPriceItems,
          item.taxRates,
          item.taxSum,
          item.amountPriceSum,
          item.docTypeLabel,
          item.acceptStatusLabel as string,
        ]),
      ];

      const preparedOutcomeItems = [
        ...outcomeMappedItems.map((item) => [
          item.serialNumber,
          item.invoiceDate,
          item.uuid,
          item.parentNumber,
          item.buyerTaxNumber,
          item.overallPriceItems,
          item.taxRates,
          item.taxSum,
          item.amountPriceSum,
          item.docTypeLabel,
          item.acceptStatusLabel as string,
        ]),
      ];

      const { data: file } = await axios.get(
        `/template/${i18n.locale}/ЛК НП. Данные контрагента.xlsx`,
        {
          responseType: 'arraybuffer',
        },
      );
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(file);
      const incomeWorksheet = workbook.getWorksheet(
        i18n.t('downloads.income') as string,
      );

      for (let i = 0; i < preparedIncomeItems.length; i++) {
        incomeWorksheet.getRow(i + 2).values = preparedIncomeItems[i];
      }

      const outcomeWorksheet = workbook.getWorksheet(
        i18n.t('downloads.outcome') as string,
      );

      for (let i = 0; i < preparedOutcomeItems.length; i++) {
        outcomeWorksheet.getRow(i + 2).values = preparedOutcomeItems[i];
      }

      const buffer = await workbook.xlsx.writeBuffer();
      downloadXlsxFromBuffer('Счета-фактуры', buffer);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async setType(type: typeof this.invoiceType) {
    await this.setPage(0);
    this.invoiceType = type;
  }

  @action.bound async fetchData(
    ignorePages = false,
    invoiceType: string | null = null,
  ) {
    try {
      const taxpayerInfo = await services.taxpayer.getTaxpayerInfo(
        this.taxNumber,
      );
      this.legalName = taxpayerInfo.data.name || i18n.t('actions.undefined');
    } catch (e) {
      this.legalName = i18n.t('actions.undefined') as string;
      console.error(e);
    }

    const { start: gte, end: lte } = this.stores.period.range;

    const queryRange = {
      range: {
        invoiceDate: { gte, lte },
      },
    };

    const queryInvoiceType = invoiceType || this.invoiceType;

    const queryType = {
      match: {
        [queryInvoiceType === 'income' ? 'buyerTaxNumber' : 'sellerTaxNumber']:
          {
            query: this.taxNumber,
          },
      },
    };

    const queryTaxNumber = {
      match: {
        [queryInvoiceType === 'income' ? 'sellerTaxNumber' : 'buyerTaxNumber']:
          {
            query: this.stores.user.taxNumber,
          },
      },
    };

    const querySearch = [
      {
        wildcard: {
          serialNumber: `${this.search}*`,
        },
      },
      {
        wildcard: {
          'uuid.keyword': `${this.search}*`,
        },
      },
      {
        wildcard: {
          'parentNumber.keyword': `${this.search}*`,
        },
      },
    ];

    const queryStatus = {
      terms: {
        ['status.keyword']: [
          'APPROVED',
          'VOID',
          'NOT_ACTUAL',
          'CREATED',
          ...(queryInvoiceType === 'outcome' ? ['DECLINED'] : []),
        ],
      },
    };

    const params: any[] = [];

    params.push(queryRange, queryStatus);

    if (queryInvoiceType) params.push(queryType, queryTaxNumber);

    // if (this.search) params.push(querySearch);

    const counts = await elasticApi.post('invoice_index/_search', {
      track_total_hits: true,
      size: 0,
      query: {
        bool: {
          must: [queryRange],
          ...(this.search && { should: querySearch, minimum_should_match: 1 }),
        },
      },
      aggs: {
        income_count: {
          filter: {
            bool: {
              must: [
                { term: { buyerTaxNumber: this.taxNumber } },
                { term: { sellerTaxNumber: this.stores.user.taxNumber } },
              ],
            },
          },
          aggs: {
            status_filter: {
              filter: {
                terms: {
                  ['status.keyword']: [
                    'APPROVED',
                    'VOID',
                    'NOT_ACTUAL',
                    'CREATED',
                  ],
                },
              },
            },
          },
        },
        outcome_count: {
          filter: {
            bool: {
              must: [
                { term: { sellerTaxNumber: this.taxNumber } },
                { term: { buyerTaxNumber: this.stores.user.taxNumber } },
              ],
            },
          },
          aggs: {
            status_filter: {
              filter: {
                terms: {
                  ['status.keyword']: [
                    'APPROVED',
                    'VOID',
                    'DECLINED',
                    'NOT_ACTUAL',
                    'CREATED',
                  ],
                },
              },
            },
          },
        },
      },
    });

    if (!ignorePages) {
      this.count = {
        income:
          counts.data?.aggregations?.income_count?.status_filter.doc_count,
        outcome:
          counts.data?.aggregations?.outcome_count?.status_filter.doc_count,
      };
    }

    const response = await elasticApi.post('invoice_index/_search', {
      from: !ignorePages ? this.page * this.pageSize : 0,
      size: !ignorePages ? this.pageSize : 10_000,
      track_total_hits: true,
      sort:
        this.sort.prop === 'overallPriceItems'
          ? [
              {
                _script: {
                  type: 'number',
                  script: {
                    source: "doc['amountPriceSum'].value - doc['taxSum'].value",
                  },
                  order: this.sort.direction,
                },
              },
            ]
          : [
              ...(this.sort.prop && [
                {
                  [this.computedSort.prop]: this.sort.direction,
                },
              ]),
            ],
      query: {
        bool: {
          must: params,
          ...(this.search && { should: querySearch, minimum_should_match: 1 }),
        },
      },
    });
    return {
      items: response.data?.data || [],
      totalItems: response.data?.totalItems || 0,
    };
  }

  @action.bound itemsMapFunction(item: Invoice) {
    return {
      ...item,
      invoiceDate: moment(item.invoiceDate).format('DD.MM.yy'),
      acceptStatusLabel: i18n.t(
        computedStatusLabel(
          item.acceptStatus,
          item.status,
          item.docType,
          this.invoiceType,
        ),
      ),
      acceptStatusColor: computedStatusColor(item.acceptStatus, item.status),
      docTypeLabel: i18n.t(InvoiceDocTypeLabel[item.docType]) as string,
      docTypeColor: InvoiceDocTypeColor[item.docType],
      parentNumber: item.parentNumber || '—',
      taxRates: getUniqueValuesString<Fee>(item.fees, 'taxRate'),
      taxSum: numGroup(item.taxSum),
      amountPriceSum: numGroup(item.amountPriceSum),
      overallPriceItems: numGroup(item.amountPriceSum - item.taxSum),
      // overallPriceItems: numGroup(
      //   getReducedNumericalField<Fee>(item.fees, 'priceItems'),
      // ),
    };
  }

  @computed get itemsView() {
    return this.items.map(this.itemsMapFunction.bind(this));
  }
}
