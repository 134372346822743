import { RouteConfig } from 'vue-router';

const PartnersView = () => import('@/views/partners/partners.vue');
const PartnersIndex = () => import('@/views/partners/index.vue');
const PartnersBuyers = () => import('@/views/partners/buyers.vue');
const PartnersSellers = () => import('@/views/partners/sellers.vue');
const PartnersTaxNumberView = () =>
  import('@/views/partners/_taxNumber/_taxNumber.vue');
const PartnersTaxNumber = () => import('@/views/partners/_taxNumber/index.vue');
const PartnerTaxNumberInvoicesIncome = () =>
  import('@/views/partners/_taxNumber/invoices-income.vue');
const PartnerTaxNumberInvoicesOutcome = () =>
  import('@/views/partners/_taxNumber/invoices-outcome.vue');
const PartnersTaxNumberUuid = () =>
  import('@/views/partners/_taxNumber/_uuid/index.vue');

export default {
  path: '/partners',
  component: PartnersView,
  children: [
    {
      path: '',
      redirect: { name: 'partners-sellers' },
      component: PartnersIndex,
      children: [
        {
          path: 'buyers',
          name: 'partners-buyers',
          component: PartnersBuyers,
        },
        {
          path: 'sellers',
          name: 'partners-sellers',
          component: PartnersSellers,
        },
      ],
    },
    {
      path: ':taxNumber',
      component: PartnersTaxNumberView,
      children: [
        {
          path: '',
          name: 'partners-id-index',
          redirect: { name: 'partners-id-invoices-income' },
          component: PartnersTaxNumber,
          children: [
            {
              path: 'invoices-income',
              name: 'partners-id-invoices-income',
              component: PartnerTaxNumberInvoicesIncome,
            },
            {
              path: 'invoices-outcome',
              name: 'partners-id-invoices-outcome',
              component: PartnerTaxNumberInvoicesOutcome,
            },
          ],
        },
        {
          path: ':uuid',
          name: 'partners-tin-index-uuid',
          component: PartnersTaxNumberUuid,
        },
      ],
    },
  ],
} as RouteConfig;
