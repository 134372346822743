import { action, computed, observable } from 'mobx';
import { elasticApi } from '@/api';
import StoreConstructor from '@/store/core/StoreConstructor';
import { numGroup } from '@/utils';
import services from '@/api/services';
import moment from 'moment';
import {
  queryOnlyAccepted,
  queryOnlyApproved,
  queryOnlyDeclined,
  queryOnlyVoidDocType,
} from '@/utils/query';

export default class Summary extends StoreConstructor {
  @observable.deep incomeInvoices: Record<string, number> = {
    partnersCount: 0,
    invoicesCount: 0,
    sum: 0,
    taxSum: 0,
  };
  @observable.deep outcomeInvoices: Record<string, number> = {
    partnersCount: 0,
    invoicesCount: 0,
    sum: 0,
    taxSum: 0,
  };
  @observable declarationStatus: boolean | undefined = undefined;

  @computed get invoicesSummaryView() {
    return {
      income: {
        partnersCount: numGroup(this.incomeInvoices.partnersCount, {
          incorrectSign: '0',
          fractionDigits: 0,
        }),
        invoicesCount: numGroup(this.incomeInvoices.invoicesCount, {
          incorrectSign: '0',
          fractionDigits: 0,
        }),
        sum: numGroup(this.incomeInvoices.sum),
        taxSum: numGroup(this.incomeInvoices.taxSum),
      },
      outcome: {
        partnersCount: numGroup(this.outcomeInvoices.partnersCount, {
          incorrectSign: '0',
          fractionDigits: 0,
        }),
        invoicesCount: numGroup(this.outcomeInvoices.invoicesCount, {
          incorrectSign: '0',
          fractionDigits: 0,
        }),
        sum: numGroup(this.outcomeInvoices.sum),
        taxSum: numGroup(this.outcomeInvoices.taxSum),
      },
      balance: numGroup(
        (this.outcomeInvoices.taxSum || 0) - (this.incomeInvoices.taxSum || 0),
      ),
    };
  }

  @observable balance: string | number = '—';

  @action.bound async fetchDeclarationStatus(
    currentYear: Date | null,
    currentMonth?: string | number,
  ) {
    const year = String(currentYear?.getFullYear());
    const month = currentMonth || moment(new Date()).format('MM');

    const date = `${year}-${month}`;

    const response = await services.taxpayer.getDeclarationInfo(
      this.stores.user.taxNumber,
      date,
    );
    this.declarationStatus = response.data?.isDecFiled;
  }

  @action.bound async fetchData() {
    const { start: gte, end: lte } = this.stores.period.range;

    const queryRange = {
      range: {
        invoiceDate: { gte, lte },
      },
    };

    const queryTaxNumber = (
      taxNumber: 'sellerTaxNumber' | 'buyerTaxNumber',
    ) => [{ match: { [taxNumber]: this.stores.user.taxNumber } }];

    const params: any[] = [];

    params.push(
      queryRange,
      queryOnlyAccepted,
      queryOnlyApproved,
      ...queryTaxNumber('buyerTaxNumber'),
    );
    const incomeInvoicesStatsResponse = await elasticApi.post(
      'invoice_index/_search',
      {
        from: 0,
        size: 0,
        query: {
          bool: {
            must: [queryRange, ...queryTaxNumber('buyerTaxNumber')],
            must_not: [queryOnlyVoidDocType, queryOnlyDeclined],
          },
        },
        aggs: {
          income_group: {
            filter: { term: { buyerTaxNumber: this.stores.user.taxNumber } },
            aggs: {
              unique_sellers: {
                cardinality: { field: 'sellerTaxNumber.keyword', missing: '' },
              },
              sum_of_taxSum: {
                sum: {
                  script:
                    "if(doc['amountPriceSum'].size() > 0 && doc['taxSum'].size() > 0  && doc['status.keyword'].value == 'APPROVED' && (doc['acceptStatus.keyword'].value == 'ACCEPTED' || doc['acceptStatus.keyword'].value == 'AUTO_ACCEPTED')) doc['taxSum'].value",
                },
              },
              sum: {
                sum: {
                  script:
                    "if(doc['amountPriceSum'].size() > 0 && doc['taxSum'].size() > 0  && doc['status.keyword'].value == 'APPROVED' && (doc['acceptStatus.keyword'].value == 'ACCEPTED' || doc['acceptStatus.keyword'].value == 'AUTO_ACCEPTED')) doc['amountPriceSum'].value",
                },
              },
            },
          },
        },
      },
    );

    const incomeInvoicesCount = await elasticApi.post('invoice_index/_search', {
      query: {
        bool: {
          must: [...queryTaxNumber('buyerTaxNumber'), queryRange],
          must_not: [{ match: { status: 'DECLINED' } }],
        },
      },
    });

    const incomeStats =
      incomeInvoicesStatsResponse.data?.aggregations?.income_group;

    this.incomeInvoices = {
      partnersCount: incomeStats?.unique_sellers?.value,
      invoicesCount: incomeInvoicesCount.data?.totalItems,
      sum: incomeStats?.sum.value,
      taxSum: incomeStats?.sum_of_taxSum?.value,
    };

    const outcomeInvoicesStatsResponse = await elasticApi.post(
      'invoice_index/_search',
      {
        from: 0,
        size: 0,
        query: {
          bool: {
            must: [queryRange, ...queryTaxNumber('sellerTaxNumber')],
            must_not: [queryOnlyVoidDocType],
          },
        },
        aggs: {
          outcome_group: {
            filter: {
              term: { sellerTaxNumber: this.stores.user.taxNumber },
            },
            aggs: {
              unique_buyers: {
                cardinality: { field: 'buyerTaxNumber.keyword', missing: '' },
              },
              sum_of_taxSum: {
                sum: {
                  script:
                    "if(doc['amountPriceSum'].size() > 0 && doc['taxSum'].size() > 0  && doc['status.keyword'].value == 'APPROVED' && (doc['acceptStatus.keyword'].value == 'ACCEPTED' || doc['acceptStatus.keyword'].value == 'AUTO_ACCEPTED')) doc['taxSum'].value",
                },
              },
              sum: {
                sum: {
                  script:
                    "if(doc['amountPriceSum'].size() > 0 && doc['taxSum'].size() > 0  && doc['status.keyword'].value == 'APPROVED' && (doc['acceptStatus.keyword'].value == 'ACCEPTED' || doc['acceptStatus.keyword'].value == 'AUTO_ACCEPTED')) doc['amountPriceSum'].value",
                },
              },
            },
          },
        },
      },
    );

    const outcomeInvoicesCount = await elasticApi.post(
      'invoice_index/_search',
      {
        query: {
          bool: {
            must: [...queryTaxNumber('sellerTaxNumber'), queryRange],
          },
        },
      },
    );

    const outcomeStats =
      outcomeInvoicesStatsResponse.data?.aggregations?.outcome_group;

    this.outcomeInvoices = {
      partnersCount: outcomeStats?.unique_buyers?.value,
      invoicesCount: outcomeInvoicesCount.data?.totalItems,
      sum: outcomeStats?.sum.value,
      taxSum: outcomeStats?.sum_of_taxSum?.value,
    };
  }
}
