import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import stores from '@/store/core/RootStore';
import Component from 'vue-class-component';

Vue.use(VueRouter);

Component.registerHooks(['beforeRouteEnter']);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.user) {
    const [taxNumber, legalPersonId] = String(to.query.user).split('-');
    stores.user.taxNumber = String(taxNumber);
    stores.user.legalPersonId = Number(legalPersonId);
    if (!stores.user.info) stores.user.getTaxpayerInfo().catch(() => false);
  }
  if (to.query.key) {
    localStorage.setItem('demoKey', to.query.key as string);
    stores.user.demoKey = localStorage.getItem('demoKey');
  }
  if (stores.user.demoKey !== 'hxygKbvT' && to.name !== 'auth-login-index') {
    next({ name: 'auth-login-index' });
  } else {
    if (!to.query.user && from.query.user) {
      to.query.user = from.query.user;
      next({
        path: to.path,
        query: to.query,
        params: to.params,
      });
    } else {
      next();
    }
  }
});

export default router;
