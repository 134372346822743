import { RouteConfig } from 'vue-router';

const InvoiceView = () => import('@/views/invoice/invoice.vue');
const InvoiceIndex = () => import('@/views/invoice/index.vue');
const InvoiceIncome = () => import('@/views/invoice/income.vue');
const InvoiceOutcome = () => import('@/views/invoice/outcome.vue');

const PartnersTaxNumberView = () =>
  import('@/views/partners/_taxNumber/_taxNumber.vue');
const PartnersTaxNumberUuid = () =>
  import('@/views/partners/_taxNumber/_uuid/index.vue');

export default {
  path: '/invoices',
  component: InvoiceView,
  children: [
    {
      path: '',
      name: 'invoice-index',
      redirect: { name: 'invoice-income' },
      component: InvoiceIndex,
      children: [
        {
          path: 'income',
          name: 'invoice-income',
          component: InvoiceIncome,
        },
        {
          path: 'outcome',
          name: 'invoice-outcome',
          component: InvoiceOutcome,
        },
      ],
    },
    {
      path: ':taxNumber',
      component: PartnersTaxNumberView,
      children: [
        {
          path: ':uuid',
          name: 'invoice-tin-index-uuid',
          component: PartnersTaxNumberUuid,
        },
      ],
    },
  ],
} as RouteConfig;
