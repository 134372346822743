import { RootStores } from '@/store/core/RootStore';
import { action, computed, observable } from 'mobx';
import moment from 'moment/moment';
import StoreConstructor from '@/store/core/StoreConstructor';
import { months } from '@/consts/date';

export default class Period extends StoreConstructor {
  @observable date: {
    month: { value: number | string; label: string } | null;
    year: Date | null;
  } = {
    month: null,
    year: new Date(),
  };

  constructor(stores: RootStores) {
    super(stores);

    const currentMonth = moment(new Date()).format('MM');
    const currentLabel = months.find(
      (month) => month.value === currentMonth,
    )?.label;

    if (currentMonth && currentLabel) {
      this.date.month = {
        value: currentMonth,
        label: currentLabel,
      };
    }
  }

  @computed get range() {
    const date = `${this.date.year?.getFullYear()}-${this.date.month?.value}`;

    return {
      start: this.date.month?.value
        ? moment(date).format('YYYY-MM-01')
        : moment(this.date.year).format('YYYY-01-01'),
      end: this.date.month?.value
        ? moment(date).endOf('month').format('YYYY-MM-DD')
        : moment(this.date.year).format('YYYY-12-31'),
    };
  }

  @action.bound setMonth(month: { value: number; label: string } | null) {
    this.date.month = month || null;
  }

  @action.bound setYear(date: Date | null) {
    this.date.year = date;
  }
}
