import moment from 'moment/moment';

export default class DateTime {
  private readonly _dateTime: string | null;

  constructor(dateTime: string | null) {
    this._dateTime = dateTime;
  }

  public get date(): string {
    return this._dateTime != null ? moment(this._dateTime).format('L') : '';
  }

  public get time(): string {
    return this._dateTime != null ? moment(this._dateTime).format('LT') : '';
  }

  public get dateTime(): string {
    return this._dateTime != null ? moment(this._dateTime).format('L LT') : '';
  }
}
