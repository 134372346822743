import { RouteConfig } from 'vue-router';

const SummaryView = () => import('@/views/summary/summary.vue');
const SummaryIndex = () => import('@/views/summary/index.vue');

export default {
  path: '/summary',
  component: SummaryView,
  children: [
    {
      path: '',
      name: 'summary-index',
      component: SummaryIndex,
    },
  ],
} as RouteConfig;
