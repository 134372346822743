import { RouteConfig } from 'vue-router';
import AuthRoutes from './auth';
import ProfileRoutes from './profile';
import SummaryRoutes from './summary';
import InvoiceRoutes from './invoice';
import PartnersRoutes from './partners';
import MessagesRoutes from './messages';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'summary-index' },
  },
  AuthRoutes,
  ProfileRoutes,
  SummaryRoutes,
  InvoiceRoutes,
  PartnersRoutes,
  MessagesRoutes,
];

export default routes;
