import { RouteConfig } from 'vue-router';

const ProfileView = () => import('@/views/profile/profile.vue');
const ProfileIndex = () => import('@/views/profile/index.vue');

const ProfileSettingsView = () =>
  import('@/views/profile/settings/settings.vue');
const ProfileSettingsIndex = () => import('@/views/profile/settings/index.vue');

export default {
  path: '/profile',
  component: ProfileView,
  children: [
    {
      path: '',
      name: 'profile-index',
      component: ProfileIndex,
      meta: { noDatePicker: 'true' },
    },
    {
      path: 'settings',
      component: ProfileSettingsView,
      children: [
        {
          path: '',
          name: 'profile-settings-index',
          component: ProfileSettingsIndex,
        },
      ],
    },
  ],
} as RouteConfig;
