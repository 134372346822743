import { DocType, InvoiceAcceptStatus, InvoiceStatus } from '@/store/types';

export const InvoiceStatusLabel: { [key in InvoiceStatus]: string } = {
  APPROVED: 'terms.invoice.inner_status.approved',
  DECLINED: 'terms.invoice.inner_status.declined',
  NOT_ACTUAL: 'terms.invoice.inner_status.not_actual',
  VOID: 'terms.invoice.inner_status.void',
  CREATED: 'terms.invoice.inner_status.created',
};

export const InvoiceStatusColor: { [key in InvoiceStatus]: string } = {
  APPROVED: 'success',
  DECLINED: 'danger',
  NOT_ACTUAL: 'neutral',
  VOID: 'neutral',
  CREATED: 'info',
};

export const InvoiceAcceptStatusLabel: {
  [key in InvoiceAcceptStatus]: string;
} = {
  NEW: 'terms.invoice.status.new',
  ACCEPTED: 'terms.invoice.status.accepted',
  AUTO_ACCEPTED: 'terms.invoice.status.auto_accepted',
  REJECTED: 'terms.invoice.status.rejected',
};

export const InvoiceAcceptStatusColor: {
  [key in InvoiceAcceptStatus]: string;
} = {
  NEW: 'info',
  REJECTED: 'danger',
  ACCEPTED: 'success',
  AUTO_ACCEPTED: 'success',
};

export const InvoiceDocTypeLabel: { [key in DocType]: string } = {
  BASIC: 'terms.invoice.type.basic',
  ANNULLING: 'terms.invoice.type.annulling',
  CORRECTIVE_BASIC: 'terms.invoice.type.corrective',
};

export const InvoiceDocTypeColor: { [key in DocType]: string } = {
  BASIC: 'success',
  ANNULLING: 'neutral',
  CORRECTIVE_BASIC: 'info',
};

export const InvoiceCardDocType = {
  BASIC: 'terms.invoice.card_type.basic',
  ANNULLING: 'terms.invoice.card_type.annulling',
  CORRECTIVE_BASIC: 'terms.invoice.card_type.corrective',
};

export const TaxRatesOptions = [
  // { value: 0, label: 0 },
  // { value: 10, label: 10 },
  { value: 20, label: 20 },
];

export const mockInfoByTin = {
  6180634987: {
    name: 'Josephine Petersen',
    email: 'josephine_petersen@gmail.com',
  },
  7541190387: {
    name: 'Isabella Jensen',
    email: 'isabella_jensen@gmail.com',
  },
};
