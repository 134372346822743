import { createUrl } from '@/utils';

const invoices = createUrl('invoices');
const print = createUrl('print');
const email = createUrl('email');

const sendInvoice = invoices('');
const nullifying = invoices(`void`);

const reject = (uuid: string) => invoices(`acceptance/reject?uuid=${uuid}`);
const accept = (uuid: string) => invoices(`acceptance/accept?uuid=${uuid}`);
const getLink = print(`getInvoice`);
const sendEmail = (tin: string, subject: string, html: string) =>
  email(`sendHTML?tin=${tin}&subject=${subject}&html=${html}`);

export const invoiceEndpoints = {
  nullifying,
  sendInvoice,
  reject,
  accept,
  getLink,
  sendEmail,
};
