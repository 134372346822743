export const queryOnlyApproved = {
  match: {
    status: {
      query: 'APPROVED',
    },
  },
};

export const queryOnlyVoidDocType = {
  match: {
    docType: 'ANNULLING',
  },
};

export const queryOnlyDeclined = {
  match: {
    docType: 'DECLINED',
  },
};

export const queryOnlyVoid = {
  match: {
    status: {
      query: 'VOID',
    },
  },
};

export const queryOnlyAccepted = {
  bool: {
    should: [
      {
        match: {
          acceptStatus: 'ACCEPTED',
        },
      },
      {
        match: {
          acceptStatus: 'AUTO_ACCEPTED',
        },
      },
    ],
    minimum_should_match: 1,
  },
};

export const queryOnlyOwned = (taxNumber: string) => ({
  multi_match: {
    query: taxNumber,
    type: 'phrase_prefix',
    fields: ['sellerTaxNumber', 'buyerTaxNumber'],
  },
});
