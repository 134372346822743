import {
  InvoiceAcceptStatusColor,
  InvoiceAcceptStatusLabel,
  InvoiceDocTypeLabel,
  InvoiceStatusColor,
  InvoiceStatusLabel,
} from '@/consts';
import { i18n } from '@/i18n';

export const createUrl = (basePath: string) => (path: string) =>
  `${basePath}${'/' + path}`;

export const getUniqueValuesString = <T>(array: T[], field: keyof T) => {
  const resultArray: any[] = [];
  array?.forEach((item) => resultArray.push(item[field]));
  return Array.from(new Set(resultArray)).join(', ');
};

export const getReducedNumericalField = <T>(array: T[], field: keyof T) => {
  return array.reduce(
    (resultArray, item) => resultArray + Number(item[field]),
    0,
  );
};

export const formatValue = (value: string | number) =>
  parseFloat(Number(value).toFixed(2)) || 0;

type NumGroupConfig = {
  incorrectSign?: string;
  fraction?: boolean;
  fractionDigits?: number;
};
const defaultConfig: NumGroupConfig = {
  incorrectSign: '—',
  fraction: true,
  fractionDigits: 2,
};

export function numGroup(
  value: number | null | undefined | '',
  options?: NumGroupConfig,
) {
  const opts = Object.assign(
    { ...defaultConfig },
    options || {},
  ) as Required<NumGroupConfig>;
  if (value === null || value === undefined || value === '')
    return opts.incorrectSign;
  return Number(value.toFixed(2)).toLocaleString(undefined, {
    ...(opts.fraction && {
      minimumFractionDigits: opts.fractionDigits,
      maximumFractionDigits: opts.fractionDigits,
    }),
  });
}

export const computedStatusLabel = (
  acceptStatus: keyof typeof InvoiceAcceptStatusLabel,
  flcStatus: keyof typeof InvoiceStatusLabel,
  docType?: keyof typeof InvoiceDocTypeLabel,
  invoiceType?: string,
) => {
  if (['VOID', 'NOT_ACTUAL', 'DECLINED'].includes(flcStatus)) {
    return InvoiceStatusLabel[flcStatus];
  }

  if (
    docType &&
    invoiceType === 'outcome' &&
    ['ANNULLING', 'CORRECTIVE_BASIC'].includes(docType) &&
    acceptStatus === 'NEW' &&
    flcStatus === 'APPROVED'
  ) {
    return 'terms.invoice.inner_status.sended';
  }

  return (
    InvoiceAcceptStatusLabel[acceptStatus] ||
    InvoiceStatusLabel[flcStatus] ||
    'terms.invoice.inner_status.no_status'
  );
};

export const computedStatusColor = (
  acceptStatus: keyof typeof InvoiceAcceptStatusColor,
  flcStatus: keyof typeof InvoiceStatusColor,
) => {
  if (['VOID', 'NOT_ACTUAL', 'DECLINED'].includes(flcStatus)) {
    return InvoiceStatusColor[flcStatus];
  }

  return (
    InvoiceAcceptStatusColor[acceptStatus] ||
    InvoiceStatusColor[flcStatus] ||
    'neutral'
  );
};

export const downloadXlsxFromBuffer = <T extends ArrayBuffer>(
  filename: string,
  buffer: T,
) => {
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  if (link.download !== undefined) {
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const downloadFileFromBuffer = (
  filename: string,
  buffer: any,
  mediaType: string,
): void => {
  const blob = new Blob([buffer], {
    type: mediaType,
  });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  if (link.download !== undefined) {
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const downloadFile = (
  blob: Blob,
  name: string | number = 'invoice',
  ext?: string,
) => {
  const href = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${name}.${ext || 'pdf'}`); //or any other extension
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const computedTinLabel = (tin: string) =>
  tin === '-' || tin === '—' ? i18n.t('terms.taxpayer.defaulter') : tin;
