import { ApiService } from '@/api/services/core';
import { authApi, stoFiscalDataApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';

export class TaxpayerService extends ApiService {
  public getTaxpayerInfo(tin: string) {
    return authApi.get(ENDPOINTS.auth.taxpayerInfo(tin));
  }

  public getDeclarationInfo(tin: string, date: string) {
    return stoFiscalDataApi.get(
      ENDPOINTS.declaration.declarationInfo(tin, date),
    );
  }
}
