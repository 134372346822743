import CreateBasicInvoice from '@/store/invoice/createBasicInvoice';
import User from '@/store/user';
import InvoiceList from '@/store/invoice/invoiceList';
import Period from '@/store/period';
import PartnerList from '@/store/partner/partnerList';
import partnerInvoiceCard from '@/store/partner/partnerCard';
import PartnerInvoices from '@/store/partner/partnerInvoices';
import Summary from '@/store/summary';
import CreateCorrectiveInvoice from '@/store/invoice/createCorrectiveInvoice';
import Helper from '@/store/helper';
import DictionariesStore from '@/store/dictionaries/dictionaries';
import MessagesList from '@/store/notification/messagesList';
import ContentList from '@/store/notification/contentList';
import Message from '@/store/notification/message';

export class RootStores {
  period = new Period(this);
  user = new User(this);
  createInvoice = new CreateBasicInvoice(this);
  createCorrectiveInvoice = new CreateCorrectiveInvoice(this);
  invoiceList = new InvoiceList(this);
  partnersList = new PartnerList(this);
  partnerInvoiceCard = new partnerInvoiceCard(this);
  partnerInvoices = new PartnerInvoices(this);
  summary = new Summary(this);
  helper = new Helper(this);

  dictionaries = new DictionariesStore(this);
  messagesList = new MessagesList(this);
  contentList = new ContentList(null, this);
  message = new Message('', this);
}

const stores = new RootStores();
stores.dictionaries.fetch();

export default stores;
